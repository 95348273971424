import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContentHero from '../components/ContentHero'
import InformationCard from '../components/InformationCard'

const AboutUsPage = props => {
  const { data } = props
  return (
    <Layout>
      <Hero
        title="Über uns"
        subtitle="Wir wollen echte Werte schaffen und nachhaltig den Erfolg sichern"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="Wer sind wir"
          subtitle="  Wir sehen uns als Problemlöser und helfen unseren Kunden dabei, mit Hilfe moderner Methoden und Technologien geeignete Lösungen für Ihre Bedürfnisse zu finden.
        Unsere Stärke liegt darin, dass wir die Bereiche miteinander kombinieren und damit den Kunden rechts- und informationssichere IT Projekte anbieten können.
        Wir stehen immer mit Rat und Tat zur Seite, schaffen echte Werte und sichern somit den Erfolg und die Zukunft unserer Kunden ab. Wir sind aber keine stereotypen Berater.
        Unsere Beratung endet erst mit einer konkreten, messbaren und spürbaren Verbesserung für den Kunden.
        Wir beraten professionell, denken innovativ und lösen zuverlässig digitale Herausforderungen."
        />

        <div className="columns">
          <div className="column is-third">
            <InformationCard
              img={data.mission.childImageSharp}
              title="Unsere Mission"
              content="Uns ist wichtig, dass unser Beitrag zu einer konkreten, messbaren und spürbaren Verbesserung für die Unternehmen führt. In diesem Sinne sind wir 
              Begleiter unserer Kunden auf dem Weg zum digitalen Champion."
            />
          </div>
          <div className="column is-third">
            <InformationCard
              img={data.hands.childImageSharp}
              title="Wie wir arbeiten"
              content="Wir arbeiten agil und ausgerichtet an konkreten Kundenanforderungen, steuern neue Impulse bei und lösen digitale Herausforderungen 
              stets mit Blick auf hohe technische Qualität und umfassende rechtliche Sicherheit. "
            />
          </div>
          <div className="column is-third">
            <InformationCard
              img={data.team.childImageSharp}
              title="Unser Team"
              content="Das Team aus Sicherheitsexperten, spezialisierten Juristen, Cloud-Architekten und Ingenieuren sorgt mit regionaler Präsenz, 
              umfassendem Know-how und pragmatischer Vorgehensweise für eine technisch optimale und gleichzeitig rechtssichere Umsetzung digitaler Vorhaben."
            />
          </div>
          <div className="column is-third">
            <InformationCard
              img={data.partner.childImageSharp}
              title="Technologien und Partner"
              content="Unser Antrieb ist es, technologisch vorne mit dabei zu sein. Wir setzen daher auf die aktuellsten Trends und Tools der Softwareentwicklung.
            Neben .NET, .NET Core, Java, C# und Kotlin gehören auch Terraform, Kubernetes, Docker und Azure zu unseren Spezialitäten.
            Wir arbeiten eng mit unseren Partnern wie Elastic, Hashicorp und Microsoft zusammen, um die optimale Lösung für unsere Kunden zu finden."
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

AboutUsPage.propTypes = {
  data: PropTypes.shape({
    allAboutUsYaml: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default AboutUsPage

// TODO: Just a placeholder, adjust for specific data
export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "junction.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    mission: file(relativePath: { eq: "mission-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 800, width: 700)
      }
    }
    hands: file(relativePath: { eq: "hands-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 800, width: 700)
      }
    }
    team: file(relativePath: { eq: "team-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 800, width: 700)
      }
    }
    partner: file(relativePath: { eq: "partner-color-mod.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 800, width: 700)
      }
    }
  }
`
